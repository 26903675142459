

:root {
    --ColorTypeG: #395EEC;
    --ColorTypeH: #595E68;
  }



/* POS 공통 */

.TSto {
    width: 100%;
    height: 100%;
}

.TSto .Inner {
    width: 90%;
    margin: auto;
    max-width: 600px;
}

.TSto .form-control.InputType {
    width: 100%;
    height: auto;
    padding: 1.25rem 1.5rem;
    border-radius: 16px;
    border: none;
    background-color: #F2F4F6;
    font-weight: var(--Regular);
    font-size: 27px;
    color: var(--ColorTypeB);
}

.TSto .form-control.InputType::placeholder {
    color: #D1D6DB;
}

.TSto .form-control.InputType:placeholder-shown {
    text-overflow: ellipsis;
}

.TSto .form-control.InputType:valid {
    border-color: var(--ColorTypeB);
    box-shadow: none;
}

.TSto .form-control.InputType:focus {
    border-color: var(--ColorTypeB);
    box-shadow: none;
}

.TSto .form-control.InputType.active {
    border-color: var(--ColorTypeA);
    box-shadow: none;
}

.TSto .btn-default {
    width: 100%;
    height: auto;
    border-radius: 16px;
    padding: 1.25rem 1.5rem;
    background: var(--ColorTypeB);
    font-weight: var(--Medium);
    font-size: 35px;
    border-color: var(--ColorTypeB);
    color: #fff;
}

.TSto .btn-default:hover,
.TSto .btn-default:focus,
.TSto .btn-default.btn:first-child:active,
.TSto :not(.btn-check)+.btn-default:active {
    color: #fff;
    background: var(--ColorTypeB);
    border-color: var(--ColorTypeB);
    box-shadow: none;
}

.TSto .btn-default2 {
    width: 100%;
    height: auto;
    border-radius: 16px;
    padding: 1.25rem 1.5rem;
    background: var(--ColorTypeG);
    font-weight: var(--Medium);
    font-size: 35px;
    border-color: var(--ColorTypeG);
    color: #fff;
}

.TSto .btn-default2:hover,
.TSto .btn-default2:focus,
.TSto .btn-default2.btn:first-child:active,
.TSto :not(.btn-check)+.btn-default2:active {
    color: #fff;
    background: var(--ColorTypeG);
    border-color: var(--ColorTypeG);
    box-shadow: none;
}

.TSto .btn-primary {
    width: 100%;
    height: auto;
    border-radius: 16px;
    padding: 1.25rem 1.5rem;
    background: var(--ColorTypeA);
    font-weight: var(--Medium);
    font-size: 35px;
    border-color: var(--ColorTypeA);
}

.TSto .btn-primary:hover,
.TSto .btn-primary:focus,
.TSto .btn-primary.btn:first-child:active,
.TSto :not(.btn-check)+.btn-primary:active {
    color: #fff;
    background-color: var(--ColorTypeA);
    border-color: var(--ColorTypeA);
    box-shadow: none;
}

.TSto .btn-secondary {
    width: 100%;
    height: auto;
    background: #f7f7f7;
    border-radius: 16px;
    padding: 1.25rem 1.5rem;
    border: 1px solid #f7f7f7;
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 35px;
}

.TSto .btn-secondary:hover,
.TSto .btn-secondary:focus,
.TSto .btn-secondary.btner,
.TSto .btn-secondary.btn:first-child:active,
.TSto :not(.btn-check)+.btn-secondary:active {
    color: var(--ColorTypeB);
    background-color: #f7f7f7;
    border-color: #f7f7f7;
    box-shadow: none;
}

.TSto .btn-secondary2 {
    width: 100%;
    height: auto;
    background: var(--ColorTypeH);
    border-radius: 16px;
    padding: 1.25rem 1.5rem;
    border: 1px solid var(--ColorTypeH);
    font-weight: var(--Medium);
    color: #fff;
    font-size: 35px;
}

.TSto .btn-secondary2:hover,
.TSto .btn-secondary2:focus,
.TSto .btn-secondary2.btner,
.TSto .btn-secondary2.btn:first-child:active,
.TSto :not(.btn-check)+.btn-secondary2:active {
    color: #fff;
    background-color: var(--ColorTypeH);
    border-color: var(--ColorTypeH);
    box-shadow: none;
}

.TSto .btn-secondary3 {
    width: 100%;
    height: auto;
    background: #2F3545;
    border-radius: 16px;
    padding: 1.25rem 1.5rem;
    border: 1px solid #2F3545;
    font-weight: var(--Medium);
    color: #fff;
    font-size: 35px;
}

.TSto .btn-secondary3:hover,
.TSto .btn-secondary3:focus,
.TSto .btn-secondary3.btner,
.TSto .btn-secondary3.btn:first-child:active,
.TSto :not(.btn-check)+.btn-secondary3:active {
    color: #fff;
    background-color: #2F3545;
    border-color: #2F3545;
    box-shadow: none;
}

.TSto .btn-light {
    width: 100%;
    height: auto;
    background: #fff;
    border-radius: 16px;
    padding: 1.25rem 1.5rem;
    border: 1px solid var(--ColorTypeB);
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 35px;
}

.TSto .btn-light:hover,
.TSto .btn-light:focus,
.TSto .btn-light.btn:first-child:active,
.TSto :not(.btn-check)+.btn-light:active {
    color: var(--ColorTypeB);
    background-color: #fff;
    border-color: var(--ColorTypeB);
    box-shadow: none;
}

.TSto a.btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TSto .AlertBox {
    width: 100%;
    min-height: 60px;
    border-radius: 100px;
    padding: 1.25rem 0.25rem;
    border: 1px solid var(--ColorTypeE);
    font-weight: var(--Medium);
    color: var(--ColorTypeA);
    font-size: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 12px 1px rgb(0 0 0 / 15%);
    line-height: 1.3;
    text-align: center;
    /* opacity: 0;
    transform: translateY(-1rem);
    transition: all 0.35s ease; */
}

/* .TSto .AlertBox.active {
    opacity: 1;
    transform: translateY(0);
  } */

.TSto .btn-clear {
    border: 0px;
    background: none;
    padding: 0px;
}


/* modal */
.modal-backdrop {
    --bs-backdrop-opacity: 0.3;
}

.TSto .modal-dialog.modal-sm {
    width: 80%;
    margin: auto;
    height: 100%;
    --bs-modal-width: 800px;
}

.TSto .modal-content {
    border: 0;
    padding: 4.5rem 0 0;
    border-radius: 24px;
}

.TSto .modal-header {
    padding: 0 1.5rem;
    border-bottom: 0px;
}

.TSto .modal-title {
    font-weight: var(--Semibold);
    color: #2F3545;
    font-size: 42px;
    width: 100%;
}

.TSto .modal-body {
    padding: 0.5rem 1.5rem;
    font-weight: var(--Medium);
    color: #8B95A1;
    font-size: 27px;
    word-break: keep-all;
    line-height: 1.3;
}

.TSto .modal-footer {
    border-top: 0px;
    padding: 3.5rem 0 2.5rem;
}

.TSto .modal-footer .bar {
    width: 2px;
    height: 40px;
    background: #D3D3D3;
}

.TSto .btn-modal-secondary,
.TSto .btn-modal-primary {
    margin: 0;
}

.TSto .btn-modal-secondary {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    background-color: #fff;
    padding: 1.25rem 0.25rem;
    font-size: 35px;
    border-radius: 0;
}

.TSto .btn-modal-secondary:hover,
.TSto .btn-modal-secondary:focus,
.TSto .btn-modal-secondary.btn:first-child:active,
.TSto :not(.btn-check)+.btn-modal-secondary:active {
    color: var(--ColorTypeB);
    background-color: #fff;
    box-shadow: none;
}


.TSto .btn-modal-primary {
    font-weight: var(--Medium);
    background-color: #fff;
    color: var(--ColorTypeG);
    padding: 1.25rem 0.25rem;
    font-size: 35px;
    border-radius: 0;
}

.TSto .btn-modal-primary:hover,
.TSto .btn-modal-primary:focus,
.TSto .btn-modal-primary.btn:first-child:active,
.TSto :not(.btn-check)+.btn-modal-primary:active {
    background-color: #fff;
    color: var(--ColorTypeG);
    box-shadow: none;
}


/* sub-navbar */

.TSto .xPadding {
    padding-left: 3rem;
    padding-right: 3rem;
}

.TSto .sub-navbar {
    padding: 1.5rem 3rem;
    position: relative;
}

.TSto .sub-navbar .navbar-title {
    font-weight: var(--Semibold);
    color: var(--ColorTypeB);
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
}

.TSto .sub-navbar .btnClose {
    position: absolute;
    right: 3rem;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: auto;
    margin: 0px;
    opacity: 1;
    border-radius: 0;
}

.TSto .sub-navbar .btnBack {
    position: absolute;
    left: 3rem;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;
    height: auto;
    margin: 0px;
    opacity: 1;
    border-radius: 0;
}


/* accordion */
.TSto .accordion {
    --bs-accordion-color: var(--ColorTypeB);
    --bs-accordion-border-radius: 0.4rem;
    --bs-accordion-btn-color: var(--ColorTypeB);
    --bs-accordion-btn-bg: #fff;
    --bs-accordion-btn-icon: url(https://www.flexdaycdn.net/public/images/crm/arrow.png);
    --bs-accordion-btn-active-icon: url(https://www.flexdaycdn.net/public/images/crm/arrow.png);
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-active-bg: #fff;
}

.TSto .accordion .accordion-item {
    overflow: hidden;
    border: none;
    max-width: 800px;
    margin: auto;
}

.TSto .accordion-button {
    color: var(--ColorTypeB);
    background: #fff;
    font-weight: var(--Medium);
    font-size: 20px;
    padding: 1rem 1.5rem;
}

.TSto .accordion-button:not(.collapsed) {
    color: var(--ColorTypeB);
    background: #fff;
    box-shadow: none;
}

.TSto .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.TSto .accordion-button span {
    color: var(--ColorTypeA);
    font-size: 0.6em;
    margin-left: 0.75rem;
}

.TSto .accordion-button::after {
    background-position: center;
}

.TSto .accordion-body {
    padding: 0;
}

.TSto .accordion-body .txtbox {
    margin-bottom: 1.8rem;
    padding: 0 1.5rem;
}

.TSto .accordion-body .txtbox .title {
    color: var(--ColorTypeB);
    font-size: 18px;
    font-weight: var(--Medium);
    border-bottom: 2px solid #dedfe3;
    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;
}

.TSto .accordion-body .txtbox .txt-item {
    font-size: 18px;
    font-weight: var(--Medium);
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
}

.TSto .accordion-body .txtbox .txt-item:last-child {
    margin-bottom: 0;
}

.TSto .accordion-body .txtbox .txt-item .tit {
    color: #999999;
    width: 50%;
}

.TSto .accordion-body .txtbox .txt-item .desc {
    width: 50%;
    text-align: right;
    word-break: break-word;
}

.TSto .accordion-body .txtbox .menu-item {
    font-size: 18px;
    font-weight: var(--Medium);
    margin-bottom: 0.25rem;
}

.TSto .accordion-body .txtbox .menu-item:last-child {
    margin-bottom: 0;
}

.TSto .accordion-body .txtbox .menu-item .tit .bold {
    font-size: 1.2em;
}

.TSto .accordion-body .txtbox .menu-item .desc {
    padding-left: 1.25rem;
    word-break: break-word;
}

.TSto .accordion-body .txtbox .menu-item .desc img {
    max-width: 12px;
    display: inline-block;
    margin-bottom: 0.75em;
    margin-right: 0.1rem;
}

.TSto .accordion-body .TSto-btnbox {
    display: flex;
}

.TSto input::-webkit-outer-spin-button,
.TSto input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.ColorA {
    color: var(--ColorTypeA) !important;
}

.ColorG {
    color: var(--ColorTypeG) !important;
}



/* 주문 거절 모달*/

.TSto #modal_orderRefuse .modal_close {
    position: absolute;
    right: 1.75rem;
    top: 2rem;
    max-width: 26px;
}

.TSto #modal_orderRefuse .modal-title {
    font-size: 36px;
    font-weight: var(--Bold);
}

.TSto #modal_orderRefuse .form-check {
    padding-left: 0;
    margin-bottom: 0;
}

.TSto #modal_orderRefuse .modal-body {
    padding: 0px 2.5rem;
    margin-top: 3rem;
}


.TSto #modal_orderRefuse .form-check+.form-check {
    margin-top: 1rem;
}

.TSto #modal_orderRefuse .form-check .form-check-input {
    display: none;
}

.TSto #modal_orderRefuse .form-check .form-check-label {
    border: 2px solid #D1D6DB;
    border-radius: 12px;
    width: 100%;
    padding: 1rem 1.25rem;
    text-align: left;
    padding-left: 5rem;
    background: url(https://www.flexdaycdn.net/public/images/ticket_store/checkbox_icon1.png) no-repeat 1.25rem center / 2.5rem #fff;
    font-weight: 600;
    font-size: 30px;
    color: var(--ColorTypeB);
    transition: all 0.4s ease;
}

.TSto #modal_orderRefuse .form-check .form-check-input:checked+.form-check-label {
    border-color: var(--ColorTypeG);
    color: var(--ColorTypeG);
    background-image: url(https://www.flexdaycdn.net/public/images/ticket_store/checkbox_icon2.png);
    background-color: #E8EDFF;
}

.TSto #modal_orderRefuse .modal-footer {
    border-top: 0px;
    padding: 0 2.5rem;
    margin-bottom: 2.5rem;
}

.TSto #modal_orderRefuse .modal-footer .btn-modal-primary {
    background-color: var(--ColorTypeG);
    color: #fff;
    border-radius: 16px;
    font-size: 30px;
}