.TStorePage {
    width: 100%;
    height: 100%;
}

.TStorePage::before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 15%;
    max-width: 125px;
    height: 100%;
    background-color: #fff;
    z-index: -1;
}

.TStorePage.StoreClose::before {
    display: none;
}

.TStorePage::after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #F2F4F6;
    z-index: -2;
}

.TStorePage .StoHeader {
    background-color: #2F3545;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.25rem;
    /* height: 80px; */
    height: 100px;
}

.TStorePage .StoHeader .sub_txt {
    /* font-size: 15px; */
    color: #ADB7C1;
    margin-left: 0.75rem;
    font-size: 28px;
}

.TStorePage .StoHeader .left {
    max-width: 50%;
    align-items: flex-end;
}

.TStorePage .StoHeader .left .name {
    font-weight: var(--Bold);
    /* font-size: 23px; */
    color: #fff;
    font-size: 32px;
}

.TStorePage .StoHeader .left .ver {
    font-weight: var(--Medium);
    /* font-size: 15px; */
    color: #FFFFFF;
    margin-left: 1rem;
    font-size: 25px;
}

.TStorePage .StoHeader .right {
    align-items: center;
}

.TStorePage .StoHeader .right .contact {
    margin-left: 0;
    margin-right: 1rem;
}

.TStorePage .StoHeader .right .btn-storeClose {
    /* font-weight: var(--Medium); */
    /* font-size: 14px; */
    color: #4E596D;
    background: #F2F4F6;
    border-radius: 6px;
    /* padding: 0.3rem 0.65rem; */
    padding: 0.5rem 1rem;
    font-weight: var(--Semibold);
    font-size: 32px;
}

.TStorePage .StoHeader .right .btn-storeClose.off {
    background: #4E596D;
    color: #ADB7C1;
}

.TStorePage .StoWrap {
    display: flex;
    /* height: calc(100% - 80px); */
    height: calc(100% - 100px);
    overflow: auto;
}

.TStorePage .StoWrap .SideNavbar {
    /* width: 15%; */
    /* max-width: 125px; */
    width: 16%;
}

.TStorePage.StoreClose .StoWrap .SideNavbar {
    display: none;
}

.TStorePage .StoWrap .SideNavbar .side-item {
    width: 100%;
    /* min-height: 135px; */
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--ColorTypeB);
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #DEDFE3;
}

.TStorePage .StoWrap .SideNavbar .side-item.acitve {
    background-color: var(--ColorTypeG);
    border-color: var(--ColorTypeG);
    color: #fff;
}

.TStorePage .StoWrap .SideNavbar .side-item .number {
    font-size: 60px;
    line-height: 1.2;
    font-weight: var(--Bold);
    word-break: break-word;
}

.TStorePage .StoWrap .SideNavbar .side-item .txt {
    /* font-size: 20px; */
    line-height: 1.2;
    font-size: 40px;
    font-weight: 600;
}

.TStorePage .StoWrap .ContentsWrap {
    flex: 1;
    min-height: 100%;
    height: 100%;
    overflow: hidden;
    padding: 3rem 2rem 0;
    position: relative;
    padding-right: 0.5rem;
}


.TStorePage .StoWrap .ContentsWrap.no-order {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0;
}

.TStorePage .StoWrap .ContentsWrap .no_order_txt {
    font-weight: var(--Semibold);
    /* font-size: 35px; */
    color: #D1D6DB;
    text-align: center;
    margin-bottom: 4vh;
    font-size: 65px;
}

.TStorePage.StoreClose .StoWrap .ContentsWrap .no_order_txt .btn-openStore {
    width: auto;
    display: block;
    margin: 4rem auto 0;
    padding: 1rem 3rem;
    font-size: 60px;
}

.TStorePage .StoWrap .ContentsWrap .order_list {
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    padding-right: 2rem;
    padding-bottom: 3rem;
}

.TStorePage .StoWrap .ContentsWrap .order_list::-webkit-scrollbar {
    width: 2rem;
}

.TStorePage .StoWrap .ContentsWrap .order_list::-webkit-scrollbar-thumb {
    background: #FFFFFF;
    border: 3px solid #D1D6DB;
    border-radius: 8px;
    background: url(https://www.flexdaycdn.net/public/images/ticket_store/scrollbar_icon.png) no-repeat center center / 50% auto #fff;
}

.TStorePage .StoWrap .ContentsWrap .order_list::-webkit-scrollbar-track {
    background: #D1D6DB;
    border-radius: 8px;
    margin-bottom: 3rem;
}

.TStorePage .StoWrap .ContentsWrap .order_item {
    background: #FFFFFF;
    border-radius: 16px;
    padding: 1.75rem 2rem;
    border: 1px solid var(--ColorTypeG);
    transition: all 0.4s ease-in-out;
    cursor: pointer;
}

.TStorePage .StoWrap .ContentsWrap .order_item.collapsed {
    border-color: transparent;
}

.TStorePage .StoWrap .ContentsWrap .order_item+.order_item {
    margin-top: 1.5rem;
}

.TStorePage .StoWrap .ContentsWrap .order_top {
    width: 100%;
    align-items: center;
}

.TStorePage .StoWrap .ContentsWrap .order_titbox {
    flex: 1;
    width: 50%;
}

.TStorePage .StoWrap .ContentsWrap .order_num {
    font-weight: 400;
    /* font-size: 21px; */
    color: var(--ColorTypeG);
    display: flex;
    align-items: center;
    transition: all 0.4s ease-in-out;
    font-size: 35px;
}

.TStorePage .StoWrap .ContentsWrap .order_item.collapsed .order_num {
    color: var(--ColorTypeB);
}

.TStorePage .StoWrap .ContentsWrap .order_num .num {
    font-weight: 700;
    /* font-size: 40px; */
    margin-left: 0.75rem;
    font-size: 60px;
}

.TStorePage .StoWrap .ContentsWrap .order_name {
    font-weight: 600;
    /* font-size: 40px; */
    color: #000000;
    flex: 1;
    padding: 0 1.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.25s ease-in-out;
    display: none;
    font-size: 80px;
}

.TStorePage .StoWrap .ContentsWrap .order_item.collapsed .order_name {
    display: block;
}

.TStorePage .StoWrap .ContentsWrap .order_device {
    font-size: 32px;
    color: #395EEC;
    display: flex;
    align-items: center;
    margin-left: 1rem;
    font-weight: 500;
}

.TStorePage .StoWrap .ContentsWrap .order_device>img {
    max-width: 40px;
}

.TStorePage .StoWrap .ContentsWrap .order_device .txt {
    margin-left: 0.5rem;
}

.TStorePage .StoWrap .ContentsWrap .order_item.collapsed .order_device>img {
    filter: grayscale(0.9) brightness(1.75);
    -webkit-filter: grayscale(0.9) brightness(1.75);
}

.TStorePage .StoWrap .ContentsWrap .order_item.collapsed .order_device .txt {
    display: none;
}

.TStorePage .StoWrap .ContentsWrap .order_statebox {
    align-items: center;
}

.TStorePage .StoWrap .ContentsWrap .order_time {
    font-weight: 600;
    /* font-size: 21px; */
    color: var(--ColorTypeG);
    transition: all 0.4s ease-in-out;
    font-size: 40px;
}

.TStorePage .StoWrap .ContentsWrap .order_picktime {
    font-weight: 600;
     /* font-size: 21px; */
    /* color: var(--ColorTypeG); */
    font-size: 30px;
    color: var(--ColorTypeC);
    transition: all 0.4s ease-in-out;
    margin-left: 1rem;
}

.TStorePage .StoWrap .ContentsWrap .order_picktime2 {
    font-weight: 600;
    font-size: 30px;
    color: #ADB7C1;
    transition: all 0.4s ease-in-out;
    margin-left: 1rem;
}

.TStorePage .StoWrap .ContentsWrap .order_item.collapsed .order_time {
    color: #4E596D;
}


.TStorePage .StoWrap .ContentsWrap .order_btnbox {
    align-items: center;
    margin-left: 2rem;
    min-width: 250px;
}

.TStorePage .StoWrap .ContentsWrap .order_btnbox .btn {
    font-weight: 600;
    /* font-size: 22px; */
    padding: 0.6rem 2.5rem;
    border-radius: 12px;
    white-space: nowrap;
    font-size: 45px;
}

.TStorePage .StoWrap .ContentsWrap .order_btnbox .btn+.btn {
    margin-left: 1rem;
}

.TStorePage .StoWrap .ContentsWrap .order_arrow {
    width: 25px;
    margin-left: 1.75rem;
    transition: all 0.4s ease-in-out;
}

.TStorePage .StoWrap .ContentsWrap .order_item.collapsed .order_arrow {
    transform: rotate(-180deg);
    filter: grayscale(0.9) brightness(1.75);
    -webkit-filter: grayscale(0.9) brightness(1.75);
}

.TStorePage .StoWrap .ContentsWrap .order_contbox .title {
    font-weight: 600;
    font-size: 40px;
    color: #919CA6;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_menulist {
    margin-top: 2.5rem;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_menulist .title {
    margin-bottom: 0.75rem;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_menulist .prd_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_menulist .prd_info .prd_name {
    font-weight: 600;
    font-size: 75px;
    color: #000000;
    flex: 1;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_menulist .prd_info .prd_count {
    font-weight: 600;
    font-size: 70px;
    margin-left: 1rem;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_infobox {
    margin-top: 2rem;
    padding: 2.5rem 0 2rem;
    border-top: 1px solid #ADB7C1;
    display: flex;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_infobox .orderbox,
.TStorePage .StoWrap .ContentsWrap .order_cont_infobox .paybox {
    width: 35%;
    padding-right: 1rem;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_infobox .userbox {
    width: 30%;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_infobox .title {
    margin-bottom: 1.5rem;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_infobox .cont+.cont {
    margin-top: 0.5rem;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_infobox .cont .sub {
    font-weight: 500;
    font-size: 30px;
    color: #4E596D;
    width: 27%;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_infobox .cont .desc {
    font-weight: 600;
    font-size: 30px;
    color: #4E596D;
    flex: 1;
}

.TStorePage .paperTicket_add {
    font-weight: 600;
    font-size: 50px;
    color: var(--ColorTypeB);
    position: absolute;
    left: 50%;
    bottom: 4%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(47, 53, 69, 0.25);
    border-radius: 100px;
    padding: 1.25rem 4.5rem;
}

.TStorePage .paperTicket_add>img {
    max-width: 45px;
    margin-right: 1rem;
}

.TStorePage .OrderAlert {
    position: absolute;
    left: 2rem;
    bottom: 1.5rem;
    background: rgba(47, 53, 69, 0.9);
    border-radius: 16px;
    width: calc(100% - 8rem);
    display: flex;
    align-items: center;
    padding: 1.75rem 2rem;
    transition: all 0.4s ease-in-out;
    transform: translateY(0px);
    opacity: 1;
}

.TStorePage .OrderAlert.d-none {
    display: flex !important;
    transform: translateY(125px);
    opacity: 0;
}

.TStorePage .OrderAlert .icon {
    background-color: var(--ColorTypeG);
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 1.25rem;
}

.TStorePage .OrderAlert .icon>img {
    max-width: 55%;
}

.TStorePage .OrderAlert .txt {
    font-weight: var(--Semibold);
    font-size: 45px;
    color: #FFFFFF;
}

.TStorePage .paperTicket_addBox {
    position: absolute;
    left: 2rem;
    top: 3rem;
    width: calc(100% - 4rem);
    height: calc(100% - 5rem);
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    padding: 2.5rem 2rem 0;
}

.TStorePage .paperTicket_addBox .modal_close {
    position: absolute;
    right: 2rem;
    top: 2rem;
    max-width: 26px;
}

.TStorePage .paper_orderList {
    width: 37%;
    display: flex;
    flex-direction: column;
}

.TStorePage .paper_orderList .desc_txt {
    font-weight: 500;
    font-size: 30px;
    color: #919CA6;
}

.TStorePage .paper_orderList .listbox {
    overflow: auto;
    flex: 1;
    padding-right: 2rem;
    margin-top: 1rem;
}

.TStorePage .paper_orderList .listbox::-webkit-scrollbar {
    width: 2rem;
}

.TStorePage .paper_orderList .listbox::-webkit-scrollbar-thumb {
    background: #FFFFFF;
    border: 3px solid #D1D6DB;
    border-radius: 8px;
    background: url(https://www.flexdaycdn.net/public/images/ticket_store/scrollbar_icon.png) no-repeat center center / 50% auto #fff;
}

.TStorePage .paper_orderList .listbox::-webkit-scrollbar-track {
    background: #D1D6DB;
    border-radius: 8px;
    margin-bottom: 2rem;
}

.TStorePage .paper_orderList .listbox .item {
    padding-left: 0;
}

.TStorePage .paper_orderList .listbox .item+.item {
    margin-top: 1rem;
}

.TStorePage .paper_orderList .listbox .item .form-check-input {
    display: none;
}

.TStorePage .paper_orderList .listbox .item .form-check-label {
    border: 2px solid #D1D6DB;
    border-radius: 12px;
    width: 100%;
    padding: 1rem 1.25rem;
    font-weight: 600;
    font-size: 45px;
    text-align: center;
    color: var(--ColorTypeB);
    transition: all 0.4s ease;
}

.TStorePage .paper_orderList .listbox .item .form-check-input:checked+.form-check-label {
    border-color: var(--ColorTypeG);
    color: var(--ColorTypeG);
    background-color: #E8EDFF;
}

.TStorePage .paper_addresult {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 3rem;
}

.TStorePage .paper_addresult .tit_txt {
    text-align: center;
    font-size: 50px;
    font-weight: var(--Bold);
}

.TStorePage .paper_addresult .sel_menubox {
    flex: 1;
    border-bottom: 2px solid #DEDFE3;
    padding-top: 2.5rem;
    height: 100%;
    overflow: hidden;
}

.TStorePage .paper_addresult .sel_menubox .sub_txt {
    font-weight: 600;
    font-size: 35px;
    color: #919CA6;
}

.TStorePage .paper_addresult .sel_menubox .sel_menu_wrap {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    padding-right: 1.75rem;
}

.TStorePage .paper_addresult .sel_menubox .sel_menu_wrap::-webkit-scrollbar {
    width: 2rem;
}


.TStorePage .paper_addresult .sel_menubox .sel_menu_wrap::-webkit-scrollbar-thumb {
    background: #FFFFFF;
    border: 3px solid #D1D6DB;
    border-radius: 8px;
    background: url(https://www.flexdaycdn.net/public/images/ticket_store/scrollbar_icon.png) no-repeat center center / 50% auto #fff;
}

.TStorePage .paper_addresult .sel_menubox .sel_menu_wrap::-webkit-scrollbar-track {
    background: #D1D6DB;
    border-radius: 8px;
    margin-bottom: 5rem;
}

.TStorePage .paper_addresult .sel_menubox .sel_menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.TStorePage .paper_addresult .sel_menubox .sel_menu+.sel_menu {
    margin-top: 1.5rem;
}

.TStorePage .paper_addresult .sel_menubox .sel_menu .prd-name {
    font-weight: 600;
    font-size: 45px;
    color: #000000;
}

.TStorePage .paper_addresult .sel_menubox .sel_menu .controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.TStorePage .paper_addresult .sel_menubox .sel_menu .btn-minus,
.TStorePage .paper_addresult .sel_menubox .sel_menu .btn-plus {
    width: 16%;
}

.TStorePage .paper_addresult .sel_menubox .sel_menu .count_value {
    width: 80px;
    border: 0;
    text-align: center;
    font-weight: 600;
    font-size: 40px;
    margin: 0 0.5rem;
}


.TStorePage .paper_addresult .sel_menubox .blank {
    font-size: 30px;
    text-align: center;
    color: #D1D6DB;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TStorePage .paper_addresult .sel_total {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 0 2rem;
    margin-top: 0.75rem;
    justify-content: space-between;
}

.TStorePage .paper_addresult .sel_total .total_count {
    font-weight: 600;
    font-size: 40px;
    color: var(--ColorTypeB);
}

.TStorePage .paper_addresult .sel_total .total_count>span {
    color: var(--ColorTypeG);
}

.TStorePage .paper_addresult .sel_total .btn-addpaper {
    background: var(--ColorTypeG);
    border-radius: 12px;
    color: #fff;
    font-weight: var(--Semibold);
    font-size: 40px;
    padding: 0.7rem 1.5rem;
    width: 33%;
}

.TStorePage .paper_addresult.blank .sel_total .total_count {
    opacity: 0;
    z-index: -999;
}

.TStorePage .paper_addresult.blank .sel_total .btn-addpaper {
    background-color: #D1D6DB;
}

.TStorePage .StoWrap .ContentsWrap .order_pickup_infotxt {
    margin-top: 2.5rem;
    font-size: 32px;
    color: #4E596D;
}

.TStorePage .StoWrap .ContentsWrap .order_pickup_infotxt .bold {
    font-weight: var(--Bold);
}




/* 23.06.20 수정 */

.TStorePage .StoWrap .ContentsWrap .order_cont_menulist .prd_info {
    justify-content: initial;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_menulist .prd_info .prd_name {
    flex: initial;
    word-break: break-word;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_menulist .prd_info .prd_count {
    margin-left: 1rem;
    position: relative;
    font-size: 65px;
    color: #000000;
    white-space: nowrap;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_menulist .prd_info .prd_count::before {
    content: "-";
    display: inline-block;
    margin-right: 10px;
}

.TStorePage .StoWrap .ContentsWrap .order_item {
    transition: initial;
}


.TStorePage .StoWrap .ContentsWrap .order_btnbox .btn.order_repickup {
    display: none;
}


.Toastify__toast-container.custom {
    /*left: calc(125px + 2rem);*/
    left: calc(16% + 2rem);
    bottom: 1.5rem;
    transform: none;
    width: calc(100% - 16% - 8rem);
    padding: 0;
}

.custom .Toastify__toast {
    background: rgba(47, 53, 69, 0.9);
    border-radius: 16px;
    width: 100%;
    min-height: initial;
    max-height: initial;
    display: flex;
    align-items: center;
    padding: 1.75rem 2rem;
    margin: 0;
    box-shadow: none;
    font-family: 'Pretendard', sans-serif;
}

.custom .Toastify__toast+.Toastify__toast {
    margin-top: 0.75rem;
}

.custom .Toastify__toast-body {
    font-weight: var(--Semibold);
    font-size: 50px;
    color: #FFFFFF;
    padding: 0;
}

.custom .Toastify__toast-icon {
    background-color: var(--ColorTypeG);
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 1.25rem;
}

.custom .Toastify__toast-icon>img {
    max-width: 55%;
}

.custom .Toastify__close-button {
    align-self: center;
    opacity: 1;
}

.custom .Toastify__close-button>svg {
    color: #fff;
    width: 28px;
    height: 30px;
}







/* 23.06.29 */

.TStorePage .StoWrap .ContentsWrap .order_list .sorting_btnbox {
    gap: 0 3rem;
    margin-bottom: 3rem;
    justify-content: center;
    align-items: center;
}

.TStorePage .StoWrap .ContentsWrap .order_list .sorting_btnbox .btn {
    width: 30%;
}

.TStorePage .StoWrap .ContentsWrap .order_list .sorting_btnbox .btn.on {
    background-color: #2F3545;
    color: #fff;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_state {
    display: flex;
    justify-content: flex-end;
    margin: 0.75rem 0;
}

.TStorePage .StoWrap .ContentsWrap .order_cont_state .btn {
    width: auto;
}