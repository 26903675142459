.TSLoginPage .LogoImg {
    margin-bottom: 2.5rem;
}

.TSLoginPage .LogoImg img {
    width: 100%;
    max-width: 35%;
}

.TSLoginPage .LogoImg .txt {
    font-weight: var(--Bold);
    color: var(--ColorTypeB);
    margin-top: 2rem;
    font-size: 45px;
    line-height: 1.3;
}

.TSLoginPage .LoginBox>.row {
    margin-bottom: 0.75rem;
}

.TSLoginPage .LoginBox .auto_logoin {
    display: inline-flex;
    width: 100%;
    align-items: center;
    margin: 1.25rem 0;
    padding: 0;
}

.TSLoginPage .LoginBox .auto_logoin>.form-check-input {
    width: 40px;
    height: 40px;
    background: url(https://www.flexdaycdn.net/public/images/ticket_store/check_icon.png) no-repeat 50% 58% / 60% #D1D6DD;
    border: none;
    border-radius: 50%;
    margin: 0;
    box-shadow: none;
}

.TSLoginPage .LoginBox .auto_logoin>.form-check-input:checked[type=checkbox] {
    background-color: var(--ColorTypeG);
}

.TSLoginPage .LoginBox .auto_logoin>.form-check-label {
    font-weight: var(--Semibold);
    font-size: 27px;
    color: #999999;
    margin-left: 0.75rem;
}

.TSLoginPage .LoginSubmit {
    margin-top: 1.25rem;
}