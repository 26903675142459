* {
  margin: 0;
  padding: 0;
  outline: none;
}

ol,
ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.Inner {
  width: 90%;
  margin: auto;
  max-width: 320px;
}

.MockupTitle {
  box-sizing: border-box;
  border-bottom: 2px solid #666;
  padding-bottom: 0.5em;
}

.MockupList>li {
  border-bottom: 1px solid #ddd;
}

.MockupList>li>a {
  display: block;
  position: relative;
  padding: 1em 0px;
  padding-left: 100px;
  box-sizing: border-box;
}

.MockupDate {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-weight: var(--Bold);
}

.MockupState {
  background: var(--ColorTypeA);
  color: #fff;
  font-size: 0.9rem;
  border-radius: 5px;
  padding: 2px 5px;
  margin-left: 10px;
}

.MockupState.on {
  background: var(--ColorTypeB);
}


/*Font Pretendard */

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff') format('woff');
  font-weight: 100;
  transform: rotate(0.04deg);
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff') format('woff');
  font-weight: 200;
  transform: rotate(0.04deg);
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff') format('woff');
  font-weight: 300;
  transform: rotate(0.04deg);
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  transform: rotate(0.04deg);
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
  font-weight: 500;
  transform: rotate(0.04deg);
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff');
  font-weight: 600;
  transform: rotate(0.04deg);
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
  transform: rotate(0.04deg);
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff') format('woff');
  font-weight: 800;
  transform: rotate(0.04deg);
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff') format('woff');
  font-weight: 900;
  transform: rotate(0.04deg);
  font-style: normal;
}

body {
  font-family: 'Pretendard', sans-serif;
}

:root {
  --ColorTypeA: #ED1556;
  --ColorTypeB: #231815;
  --ColorTypeC: #606060;
  --ColorTypeD: #C3C3C3;
  --ColorTypeE: #E8E8E8;

  --Light: 100;
  --Regular: 400;
  --Medium: 500;
  --Semibold: 600;
  --Bold: 700;
  --Extrabold: 800;

  --ColorNavIcon: #A1A0A1;
}

.pt-2-5 {
  padding-top: 0.75rem !important;
}

.pb-2-5 {
  padding-bottom: 0.75rem !important;
}

.pe-2-5 {
  padding-right: 0.75rem !important;
}

.ps-2-5 {
  padding-left: 0.75rem !important;
}

.px-2-5 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.py-2-5 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.p-2-5 {
  padding: 0.75rem !important;
}

.mt-2-5 {
  margin-top: 0.75rem !important;
}

.mb-2-5 {
  margin-bottom: 0.75rem !important;
}

.me-2-5 {
  margin-right: 0.75rem !important;
}

.ms-2-5 {
  margin-left: 0.75rem !important;
}

.mx-2-5 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.my-2-5 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.m-2-5 {
  margin: 0.75rem !important;
}

.pt-4-5 {
  padding-top: 2rem !important;
}

.pb-4-5 {
  padding-bottom: 2rem !important;
}

.pe-4-5 {
  padding-right: 2rem !important;
}

.ps-4-5 {
  padding-left: 2rem !important;
}

.px-4-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.py-4-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.p-4-5 {
  padding: 2rem !important;
}

.mt-4-5 {
  margin-top: 2rem !important;
}

.mb-4-5 {
  margin-bottom: 2rem !important;
}

.me-4-5 {
  margin-right: 2rem !important;
}

.ms-4-5 {
  margin-left: 2rem !important;
}

.mx-4-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.my-4-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.m-4-5 {
  margin: 2rem !important;
}

.form-control.InputType {
  width: 100%;
  height: 40px;
  padding: 0.75rem;
  border-radius: 3px;
  border: 1px solid var(--ColorTypeD);
  font-weight: var(--Regular);
  font-size: 13px;
  color: var(--ColorTypeB);
}

.form-control.InputType::placeholder {
  color: var(--ColorTypeD);
}

.form-control.InputType:focus {
  border-color: var(--ColorTypeB);
  box-shadow: none;
}

.form-control.InputType.active {
  border-color: var(--ColorTypeA);
  box-shadow: none;
}

.form-control.InputType.active~.form-text.info-txt {
  display: block;
}

.AlertBox {
  width: 100%;
  height: 0px;
  border-radius: 30px;
  border: 1px solid var(--ColorTypeE);
  font-weight: var(--Medium);
  color: var(--ColorTypeA);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 3px 10px 1px rgb(0 0 0 / 20%);
  opacity: 0;
  transform: translateY(-1rem);
}

.AlertBox.active {
  opacity: 1;
  transform: translateY(0);
  height: 45px;
  transition: all 0.35s ease;
}

.btn-default {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  background: var(--ColorTypeB);
  font-weight: var(--Medium);
  font-size: 14px;
  border-color: var(--ColorTypeB);
  color: #fff;
}

.btn-default:hover,
.btn-default:focus,
.btn-default.btn:first-child:active,
:not(.btn-check)+.btn-default:active {
  color: #fff;
  background: var(--ColorTypeB);
  border-color: var(--ColorTypeB);
  box-shadow: none;
}

.btn-primary {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  background: var(--ColorTypeA);
  font-weight: var(--Medium);
  font-size: 14px;
  border-color: var(--ColorTypeA);
  color: #fff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.btn:first-child:active,
:not(.btn-check)+.btn-primary:active {
  color: #fff;
  background-color: var(--ColorTypeA);
  border-color: var(--ColorTypeA);
  box-shadow: none;
}

.btn-secondary {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  background: var(--ColorTypeD);
  font-weight: var(--Medium);
  font-size: 14px;
  border: 1px solid var(--ColorTypeD); 
  color: #fff;  
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.btn:first-child:active,
:not(.btn-check)+.btn-secondary:active {
  color: #fff;
  background-color: var(--ColorTypeD);
  border-color: var(--ColorTypeD);
  box-shadow: none;
}

.btn-light {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  background: #fff;
  font-weight: var(--Medium);
  font-size: 14px;
  border: 1px solid var(--ColorTypeD);    
  color: #fff;  
}

.btn-light:hover,
.btn-light:focus,
.btn-light.btn:first-child:active,
:not(.btn-check)+.btn-light:active {
  color: var(--ColorTypeC);
  background-color: #fff;
  border-color: var(--ColorTypeC);
  box-shadow: none;
}

.type10.btn-light:hover,
.type10.btn-light:focus,
.type10.btn-light.btn:first-child:active,
:not(.btn-check)+.btn-light:active {
  border-color: var(--ColorTypeC);
}

.btn-check+.btn-light.type10.btn:hover {
  color: var(--ColorTypeC);
  background-color: #fff;
  border-color: var(--ColorTypeD);
}

.btn-check:checked+.type10.btn-light.btn,
.btn-check+.btn-light.type10.btn:hover {
  color: #fff;
  background-color: var(--ColorTypeA);
  border-color: var(--ColorTypeA);
}

a.btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-clear {
  border: 0px;
  background: none;
  padding: 0px;
}

.btn-add-close {
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  width: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 20%);
  font-size: 0px;
}

.btn.type5 {
  max-width: 110px;
  font-weight: var(--Regular);
}

.btn.type6 {
  max-width: 155px;
  height: 50px;
}

.btn.type7 {
  max-width: 155px;
  height: 50px;
}

.btn.type8 {
  max-width: 155px;
  height: 50px;
  font-weight: var(--Regular);
}

.btn.type9 {
  max-width: 155px;
  height: 50px;
}

.btn.type10 {
  height: 30px;
  border-radius: 30px;
  border-color: var(--ColorTypeD);
  font-size: 13px;
  line-height: 30px;
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0;
}

.btn.type11 {
  width: auto;
  height: auto;
  font-size: 13px;
  border-radius: 30px;
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 1rem;
}

.btn.type12 {
  width: auto;
  height: auto;
  font-size: 13px;
  border-radius: 30px;
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 1rem;
}

.btn.type13 {
  width: auto;
  height: auto;
  font-size: 13px;
}

.btn.type14 {
  width: auto;
  height: auto;
  font-size: 13px;
}

.btn.type15 {
  border-radius: 30px;
}

.btn.type16 {
  border-radius: 30px;
}

.form-text.info-txt {
  font-weight: var(--Medium);
  color: var(--ColorTypeA);
  font-size: 13px;
  padding: 0.25rem 0.75rem;
  display: none;
}

.container.menu {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 70px;
}

/* navbar top */
.navbar {
  background: #fff;
  border-bottom: 1px solid var(--ColorTypeD);
  padding: 1rem 0.75rem;
}

.navbar-brand {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 16px;
}

.navbar-brand.hide {
  opacity: 0;
  pointer-events: none;
}

.navbar-toggler {
  padding: 0;
  font-size: 0;
  line-height: 1;
  color: inherit;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  transition: var(--bs-navbar-toggler-transition);
  width: 28px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.offcanvas {
  max-width: 85%;
}

.offcanvas.offcanvas-end {
  border-left: none;
}

.btn-close {
  background-size: 27px auto;
}

.offcanvas-header .btn-close {
  margin-right: 3px
}


/* modal */
.modal-dialog.modal-sm {
  width: 80%;
  margin: auto;
}

.modal-content {
  border: 1px solid var(--ColorTypeD);
}

.modal-header {
  padding: 1.5rem 1.5rem 0;
  border-bottom: 0px;
}

.modal-title {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 18px;
  width: 100%;
}

.modal-body {
  padding: 0.5rem 1.5rem;
  font-weight: var(--Medium);
  color: var(--ColorTypeC);
  font-size: 13px;
}

.modal-footer {
  border-top: 0px;
  padding: 1rem 1.5rem;
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.3;
}

.btn-modal-secondary,
.btn-modal-primary {
  width: 50%;
  margin: 0.375rem 0;
}

.btn-modal-secondary {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 16px;
}

.btn-modal-secondary:hover,
.btn-modal-secondary:focus,
.btn-modal-secondary.btn:first-child:active,
:not(.btn-check)+.btn-modal-secondary:active {
  color: var(--ColorTypeB);
  box-shadow: none;
}

.btn-modal-primary {
  font-weight: var(--Medium);
  color: var(--ColorTypeA);
  font-size: 16px;
}

.btn-modal-primary:hover,
.btn-modal-primary:focus,
.btn-modal-primary.btn:first-child:active,
:not(.btn-check)+.btn-modal-primary:active {
  color: var(--ColorTypeA);
  box-shadow: none;
}

/* navber bottom  */

.b-navbar {
  transition: all 0.35s ease;
}

.b-navbar.hide {
  opacity: 0;
  margin-bottom: -100px;
}

.b-navbar {
  background: #fff;
  border-top-left-radius: 27px;
  border-top-right-radius: 27px;
  box-shadow: 0px -3px 10px 1px rgb(0 0 0 / 20%);
}

.b-navbar ul {
  padding: 0.75rem 1.5rem;
}

.b-navbar ul li {
  position: relative;
  width: 14%;
  max-width: 45px;
}

.b-navbar ul li a {
  display: block;
}

.b-navbar ul li a img:nth-child(2) {
  position: absolute;
  left: 0px;
  top: 0px;
  display: none;
}

.b-navbar ul li.active a img:nth-child(1) {
  opacity: 0;
}

.b-navbar ul li.active a img:nth-child(2) {
  display: block;
}


/* 서프페이지 navbar */

.sub-navbar {
  padding: 1rem 0.75rem;
  align-items: center;
  position: relative;
  border-bottom: 1px solid var(--ColorTypeD);
}

.sub-navbar > .btn-close {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: auto;
  padding: 0px;
  margin: 0px;
  opacity: 1;
  border-radius: 0;
}

.sub-navbar > .btn-close.ver2 {
  width: 25px;
}

.navbar-title {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 20px;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
}

.btn-back {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 9px;
  height: auto;
  padding: 0px;
  margin: 0px;
}

.addprd-inputbox.d-flex {
  gap: 0.5rem;
}

.btn.btn-refresh {
  width: 30px;
}

.btn-reset {
  font-weight: var(--Medium);
  color: var(--ColorTypeC);
  font-size: 13px;
  border-bottom: 1px solid var(--ColorTypeC);
  border-radius: 0;
  padding: 0.4rem 0px;
  align-self: center;
}

.h-40px {
  height: 40px !important;
}

.h-50px {
  height: 50px !important;
}

.gap-10px {
  gap: 0px 10px;
}

.btn-reset img {
  width: 14px;
  margin-right: 5px;
}

/* 사진선택 폼 */

.PhotoList .row {
  width: 100%;
  margin: 0;
  gap: 1.2rem;
}

.PhotoList .col-4 {
  width: calc((100% - 2.6rem)/3);
  padding: 0px;
  position: relative;
}

.PhotoList .col-4 .badge-delete {
  width: 16px;
  height: 16px;
  font-size: 0px;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 20%);
}

.PhotoList .col-4 .PhotoImgbox {
  width: 100%;
  height: 100%;
}

.PhotoList .col-4 .PhotoImgbox img {
  width: 100%;
  height: 100%;
}

.PhotoList .col-4 .badge-number {
  width: 19px;
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
}

.PhotoList-txt {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 14px;
}

.File-Box {
  background: #f6f6f6;
  border: 1px dashed var(--ColorTypeD);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.File-Box img {
  max-width: 25px;
  margin: 0px auto;
}

.File-Box p {
  font-weight: var(--Medium);
  color: var(--ColorTyepB);
  font-size: 13px;
  margin-bottom: 0;
}

.File-Box.active {
  background: var(--ColorTypeC);
  border: 1px solid var(--ColorTypeC);
}

.File-Box img:nth-child(2) {
  display: none;
}

.File-Box.active img:nth-child(1) {
  display: none;
}

.File-Box.active img:nth-child(2) {
  display: block;
}

.File-Box.active p {
  color: #fff;
}


/* etc */

.fixed-bottom.mb-footer {
  margin-bottom: calc(95px + 8vh);
}

/* textarea */

.Input-placeholderbox {
  position: relative;
}

.Input-placeholder {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 0.75rem 1.75rem;
  font-weight: var(--Regular);
  font-size: 13px;
  color: var(--ColorTypeD);
}

.Input-placeholder+textarea {
  position: relative;
}

.Input-placeholderbox textarea.form-control.InputType {
  background: transparent;
}

textarea.form-control.InputType:not(:empty)+.Input-placeholder {
  opacity: 0;
}


/* accordion (input-text) */
.accordion {
  --bs-accordion-color: var(--ColorTypeB);
  --bs-accordion-bg: #fff;
  --bs-accordion-border-color: var(--ColorTypeD);
  --bs-accordion-border-radius: 3px;
  --bs-accordion-inner-border-radius: 3px;
  --bs-accordion-btn-padding-x: 0.75rem;
  --bs-accordion-btn-padding-y: 0.75rem;
  --bs-accordion-btn-color: var(--ColorTypeB);
  --bs-accordion-btn-bg: #fff;
  --bs-accordion-btn-icon: url(https://www.flexdaycdn.net/public/images/static/store-main/store_dropdown.png);
  --bs-accordion-btn-active-icon: url(https://www.flexdaycdn.net/public/images/static/store-main/store_dropdown.png);
  --bs-accordion-btn-focus-border-color: transparent;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 0.75rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--ColorTypeB);
  --bs-accordion-active-bg: #fff;
}

.accordion-button {
  color: var(--ColorTypeB);
  font-weight: var(--Medium);
  font-size: 13px;
}

.accordion-button:not(.collapsed) {
  color: var(--ColorTypeB);
  background: #fff;
  box-shadow: none;
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  padding-top: 0.5rem;
}

.accordion-body.Input-placeholderbox .Input-placeholder {
  padding: 0.5rem 0.75rem;
}

/* accordion ver2 (input-checkbox)*/

.accordion.ver2 {
  --bs-accordion-btn-icon: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
  --bs-accordion-btn-active-icon: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
  --bs-accordion-btn-icon-width: 1rem;
  --bs-accordion-btn-icon-transform: rotate(180deg);
  --bs-accordion-btn-icon-transform2: rotate(180deg);
}


.ver2 .accordion-item {
  border: 0px;
}

.ver2 .accordion-headerd.d-flex {
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
  border-radius: var(--bs-accordion-border-radius);
}

.ver2 .accordion-headerd .form-check-input.CheckInput1 {
  margin: 0.75rem;
}

.ver2 .accordion-button {
  font-size: 16px;
}

.ver2 .accordion-button::after {
  background-position: center;
}

.ver2 .accordion-body {
  padding: 0rem 1rem 0.75rem;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check-input:active {
  filter: none;
}

.form-check-input.CheckInput1 {
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/check_circle.png);
  border: 0px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  flex: none;
  box-shadow: none;
}

.form-check-input.CheckInput1:checked[type=checkbox] {
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/signup_check_color.png);
  background-color: var(--ColorTypeA);
  border-color: var(--ColorTypeA);
}

.form-check-input.CheckInput2 {
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/check.png);
  background-size: 16px auto;
  border: 0px;
  margin-top: 0;
  margin-left: 0px;
  margin-right: 0.25rem;
  float: none;
  width: 28px;
  height: 28px;
  flex: none;
  box-shadow: none;
}

.form-check-input.CheckInput2:checked[type=checkbox] {
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/check_color.png);
  background-color: #fff;
  border-color: transparent;
}

.form-check-input.CheckInput2.small {
  background-size: 12px auto;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
}

.form-check-input.CheckInput2.small:checked[type=checkbox] {
  border: 1px solid #ddd;
}

.form-check-input.CheckInput3 {
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/signup_check.png);
  border: 0px;
  margin-top: 0;
  margin-left: 0px;
  margin-right: 0.25rem;
  float: none;
  width: 22px;
  height: 22px;
  flex: none;
  box-shadow: none;
}

.form-check-input.CheckInput3:checked[type=checkbox] {
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/signup_check_color.png);
  background-color: #fff;
  border-color: transparent;
}

.CheckLabel {
  font-weight: var(--Regular);
  font-size: 13px;
  color: var(--ColorTypeB);
}

.CheckInfoBtn {
  font-weight: var(--Regular);
  font-size: 13px;
  color: var(--ColorTypeD);
  border-bottom: 1px solid var(--ColorTypeD);
  position: absolute;
  right: 0.75rem;
}

.CheckInfoBtn:hover {
  color: var(--ColorTypeD);
}

.input-group-text {
  font-size: 16px;
  font-weight: var(--Regular);
  color: var(--ColorTypeC);
  background-color: transparent;
  border: 0px;
}

.form-select.Selectbox {
  width: 100%;
  height: 40px;
  padding: 0.75rem;
  border-radius: 3px;
  border: 1px solid var(--ColorTypeD);
  font-weight: var(--Regular);
  font-size: 13px;
  color: var(--ColorTypeB);
  line-height: 1;
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
  background-size: 14px auto;

}

.form-select.Selectbox:focus {
  border-color: var(--ColorTypeB);
  box-shadow: none;
}

select:required:invalid {
  color: var(--ColorTypeD);
}

select option {
  color: var(--ColorTypeB);
}


/* 서브 페이지 공통 */
.PageTit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 25px;
}

.PageTit2 {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 20px;
}

.PageSib {
  font-weight: var(--Regular);
  color: var(--ColorTypeC);
  font-size: 17px;
}


/* 하단 결제 박스 */

.b-paybox {
  background: #fff;
  border-top-left-radius: 27px;
  border-top-right-radius: 27px;
  box-shadow: 0px -3px 10px 1px rgb(0 0 0 / 20%);
  opacity: 0;
  margin-bottom: -100px;
  transition: all 0.35s ease;
}

.b-paybox.show {
  opacity: 1;
  margin-bottom: 0;
}

.b-paybox .bar-icon {
  width: 45px;
  height: 5px;
  background-color: var(--ColorTypeD);
  border-radius: 3px;
}

.b-paybox .btnbox {
  gap: 0px 10px;
}

.h-45px {
  height: 45px !important;
}

/* 모바일 반응형 */
@media screen and (max-width: 320px) {

  br.Mob320 {
    display: none;
  }

  /* button */
  .btn-primary {
    height: 40px;
  }

  .btn-default {
    height: 40px;
  }

  .btn-secondary {
    height: 40px;
  }

  .btn-light {
    height: 40px;
  }

  .btn.type6 {
    height: 40px;
  }

  .btn.type7 {
    height: 40px;
  }

  .btn.type8 {
    height: 40px;
  }

  .form-control.InputType.h-50px {
    height: 40px !important;
  }

  .h-45px {
    height: 40px !important;
  }

  .modal-dialog.modal-sm {
    width: 90%;
  }

  .ver2 .accordion-button {
    font-size: 14px;
  }

  .ver2 .accordion-body {
    padding: 0rem 0rem 0.5rem;
  }

  .form-check.d-flex.align-items-center.Mob320 .CheckLabel {
    padding-right: 45px;
    word-break: keep-all;
  }

  .PageTit {
    font-size: 22px;
  }

  .PageSib {
    font-size: 14px;
  }

}



/* 2023.01.18 식권 */

.demo .container-fluid.pt-70px {
  padding-top: 70px;
}

.demo .pt-4-5 {
  padding-top: 3rem !important;
}

.demo .pe-4-5 {
  padding-right: 3rem !important;
}

.demo .pb-4-5 {
  padding-bottom: 3rem !important;
}

.demo .ps-4-5 {
  padding-left: 3rem !important;
}

.demo .px-4-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.demo .py-4-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.demo .p-4-5 {
  padding: 3rem !important;
}

.demo .mt-4-5 {
  margin-top: 3rem !important;
}

.demo .me-4-5 {
  margin-right: 3rem !important;
}

.demo .mb-4-5 {
  margin-bottom: 3rem !important;
}

.demo .ms-4-5 {
  margin-left: 3rem !important;
}

.demo .mx-4-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.demo .my-4-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.demo .m-4-5 {
  margin: 3rem !important;
}


/* 메뉴 */
.demo .navbar {
  background: linear-gradient(to right, #FF195A, #FF5864, #FF655B);
  border-bottom: 0px;
  padding: 1.5rem 1rem;
}

.demo .navbar.bg-none {
  background: #fff;
  border-bottom: 1px solid var(--ColorTypeD);
}

.demo .Navbar-Toggler {
  padding: 0;
  font-size: 0;
  line-height: 1;
  color: inherit;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  transition: var(--bs-navbar-toggler-transition);
  width: 28px;
}

.demo .offcanvas .btn-close {
  box-sizing: content-box;
  width: 28px;
  height: 28px;
  background: no-repeat;
}

.demo .offcanvas .btn-close img.close {
  width: 25px;
}

.demo .offcanvas {
  --bs-offcanvas-width: 520px;
  max-width: 60%;
}

.demo .offcanvas-header {
  border-bottom: 2px solid var(--ColorTypeE);
}

.demo .offcanvas-header img.logo {
  width: 50px;
  margin-right: 15px;
}

.demo .offcanvas-header .offcanvas-title {
  width: calc(100% - 65px);
  font-weight: var(--Medium);
  font-size: 24px;
  line-height: 1.2;
  color: var(--ColorTypeB);
  word-break: break-word;
}

.demo .offcanvas-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 3rem;
  padding-right: 3rem;
}

.demo .offcanvas-body .nav-link .imgbox {
  font-size: 0;
}

.demo .offcanvas-body .nav-link .imgbox img.af {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
}

.demo .offcanvas-body .nav-link .imgbox img.img1 {
  width: 30px;
  margin-right: 18px;
  margin-left: 2px;
}

.demo .offcanvas-body .nav-link .imgbox img.img2 {
  width: 35px;
  margin-right: 15px;
}

.demo .offcanvas-body .nav-link .imgbox img.img3 {
  width: 30px;
  margin-right: 18px;
  margin-left: 2px;
}

.demo .offcanvas-body .nav-link span {
  width: calc(100% - 50px);
  font-weight: var(--Medium);
  font-size: 25px;
  color: var(--ColorTypeB);
}

.demo .offcanvas-body .nav-link.active .imgbox img.be {
  opacity: 0;
}

.demo .offcanvas-body .nav-link.active .imgbox img.af {
  opacity: 1;
}

.demo .offcanvas-body .nav-link.active span {
  color: var(--ColorTypeA);
}

.demo .offcanvas-body .btn-default {
  font-size: 25px;
  height: 65px;
  border-radius: 50px;
}

.demo .sub-navbar {
  background: linear-gradient(to right, #FF195A, #FF5864, #FF655B);
  border-bottom: 0px;
  padding: 1.5rem 1rem;
}

.demo .navbar-title {
  line-height: 34px;
  color: #fff;
  font-size: 25px;
}

.demo .btn-back {
  width: 14px;
}

.demo .sub-navbar > .btn-close {
  width: 25px;
}

.demo .form-control.InputType {
  height: 55px;
  font-size: 17px;
}

.demo .form-control.InputType~.price-txt {
  height: 55px;
  line-height: 55px;
  font-weight: var(--Regular);
  font-size: 17px;
  color: var(--ColorTypeB);
}

.demo .Input-placeholder {
  font-size: 17px;
}

.demoModal .modal-dialog.modal-sm {
  --bs-modal-width: 500px;
}

.demoModal .modal-title {
  font-size: 25px;
}

.demoModal .modal-title img.icon {
  display: block;
  width: 50px;
  margin: 0 auto 1rem;
}

.demoModal .modal-body {
  font-size: 18px;
}

.demoModal .btn-modal-secondary,
.demoModal .btn-modal-primary {
  font-size: 20px;
}

.demo .Inner {
  max-width: 500px;
}

.demo .btn-primary,
.demo .btn-default,
.demo .btn-light {
  height: 55px;
  font-size: 18px;
}

.demo .AlertBox.active {
  height: 60px;
}

.demo .AlertBox {
  font-size: 18px;
}


/* 데모 수정 */

.demo .btn.type11,
.demo .btn.type12,
.demo .btn.type13,
.demo .btn.type14 {
  height: auto;
  width: auto;
}


/* 모바일 반응형 */
@media screen and (max-width: 599px) {

  .demo .container-fluid.pt-70px {
    padding-top: 70px;
  }

  .demo .pt-4-5 {
    padding-top: 2rem !important;
  }

  .demo .pe-4-5 {
    padding-right: 2rem !important;
  }

  .demo .pb-4-5 {
    padding-bottom: 2rem !important;
  }

  .demo .ps-4-5 {
    padding-left: 2rem !important;
  }

  .demo .px-4-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .demo .py-4-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .demo .p-4-5 {
    padding: 2rem !important;
  }

  .demo .mt-4-5 {
    margin-top: 2rem !important;
  }

  .demo .me-4-5 {
    margin-right: 2rem !important;
  }

  .demo .mb-4-5 {
    margin-bottom: 2rem !important;
  }

  .demo .ms-4-5 {
    margin-left: 2rem !important;
  }

  .demo .mx-4-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .demo .my-4-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .demo .m-4-5 {
    margin: 2rem !important;
  }

  .demo .navbar {
    padding: 1rem 0.75rem;
  }

  .demo .offcanvas {
    max-width: 75%;
  }

  .demo .offcanvas-header img.logo {
    width: 35px;
    margin-right: 5px;
  }

  .demo .offcanvas-header .offcanvas-title {
    width: calc(100% - 40px);
    font-size: 18px;
  }

  .demo .offcanvas-body {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .demo .offcanvas-body .nav-link .imgbox img.img1 {
    width: 20px;
    margin-right: 13px;
  }

  .demo .offcanvas-body .nav-link .imgbox img.img2 {
    width: 25px;
    margin-right: 12px;
  }

  .demo .offcanvas-body .nav-link .imgbox img.img3 {
    width: 20px;
    margin-right: 13px;
  }

  .demo .offcanvas-body .nav-link span {
    font-size: 20px;
  }

  .demo .offcanvas-body .btn-default {
    font-size: 18px;
    height: 50px;
  }

  .demo .sub-navbar {
    padding: 1rem 0.75rem;
  }

  .demo .navbar-title {
    font-size: 20px;
  }

  .demo .btn-back {
    width: 12px;
  }

  .demo .sub-navbar > .btn-close {
    width: 18px;
  }

  .demo .form-control.InputType {
    height: 40px;
    padding: 0.75rem;
    font-size: 13px;
  }

  .demo .form-control.InputType~.price-txt {
    font-size: 13px;
    height: 40px;
    line-height: 40px;
  }

  .demo .Input-placeholder {
    font-size: 13px;
  }

  .demoModal .modal-dialog.modal-sm {
    --bs-modal-width: 300px;
  }

  .demoModal .modal-title {
    font-size: 18px;
  }

  .demo .modal-title img.icon {
    width: 35px;
    margin: 0 auto 0.5rem;
  }

  .demoModal .modal-body {
    font-size: 13px;
  }

  .demoModal .btn-modal-secondary,
  .demoModal .btn-modal-primary {
    font-size: 16px;
  }

  .demo .Inner {
    max-width: 320px;
  }

  .demo .btn-primary,
  .demo .btn-default,
  .demo .btn-light {
    height: 50px;
    font-size: 14px;
  }

  .demo .AlertBox.active {
    height: 50px;
  }

  .demo .AlertBox {
    font-size: 14px;
  }

}



@media screen and (max-width: 320px) {

  .demo .offcanvas-body .btn-default {
    height: 40px;
  }

  .demo .btn-primary,
  .demo .btn-default,
  .demo .btn-light {
    height: 40px;
  }

  .demo .form-checkbox {
    height: 40px;
  }



}


/* 2022.12.09 반응형 */

body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera*/
}

.demo {
  max-width: 800px;
  margin: auto;
}

.demo .navbar {
  max-width: 800px;
  margin: auto;
}

.demo .fixed-bottom {
  z-index: 99;
}


/* 23-01-09 수정 */

button, input, optgroup, select, textarea {
  line-height: 1.15;
}

:root {
  --ColorTypeB: #000000;
}

.demo .navbar {
  background: #fff;
  border: 1px solid var(--ColorTypeD);
  border-top: 0;
}

.demo .sub-navbar {
  background: #fff;
  border: 1px solid var(--ColorTypeD);
  border-top: 0;
}

.demo .navbar-title {
  color: var(--ColorTypeB);
}

.demo::before {
  content: "";
  width: 100%;
  max-width: 800px;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
  z-index: -1;
}

.demo .Navbar-Toggler {
  width: 38px;
}

.demo .btn-back {
  width: 17px;
}

.demo .offcanvas {
  max-width: 65%;
  --bs-offcanvas-width: 445px;
}

.demo .offcanvas-header img.logo {
  width: 60px;
  margin-right: 15px;
}

.demo .offcanvas .btn-close img.close {
  width: 20px;
}

.demo .offcanvas-header .offcanvas-title {
  width: calc(100% - 75px);
  font-weight: var(--Semibold);
}

.demo .offcanvas-header .offcanvas-title .small {
  font-size: 20px;
  font-weight: var(--Regular);
  display: block;
  margin-bottom: 0.25rem;
}

.demo .offcanvas-body {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.demo .offcanvas-body .nav-item.last {
  padding-top: 1.25rem;
  margin-top: 1rem;
  border-top: 1px solid #666666;
}

.demo .offcanvas-body .nav-link .imgbox {
  display: none;
}

.demo .offcanvas-body .nav-link {
  padding-bottom: 1.25rem !important;
}

.demo .offcanvas-body .nav-link span {
  width: 100%;
  position: relative;
  font-size: 20px;
}

.demo .offcanvas-body .nav-link span::after {
  content: "";
  background: url(https://www.flexdaycdn.net/public/images/ticket/arrow-right.png) no-repeat center / 100% 100%;
  width: 10px;
  height: 18px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.demo .offcanvas-body .btn-default {
  font-size: 20px;
  height: 60px;
  border-radius: 5px;
  background-color: #000;
}

.demo .offcanvas-body .btn-light {
  font-size: 20px;
  height: 60px;
  border-radius: 5px;
  border-color: #000000;
  color: #000000;
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.6;
}

.demo .btn-light:hover,
.demo .btn-light.btn:first-child:active,
.demo :not(.btn-check)+.btn-light:active {
  color: var(--ColorTypeB);
  background-color: #fff;
  border-color: var(--ColorTypeB);
}

.demo input::-webkit-outer-spin-button,
.demo input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.demo-modal .modal-fullscreen-lg-down .modal-content {
  overflow-x: auto;
}

.demo-modal .modal-header {
  align-items: baseline;
  padding-bottom: 1rem;
}

.demo-modal .modal-header .modal-title {
  word-break: keep-all;
  font-size: 25px;
}

.demo-modal .modal-header .modal-title small {
  font-size: 0.75em;
}

.demo-modal .modal-header .btn-close {
  background: url(https://www.flexdaycdn.net/public/images/ticket/close.png) no-repeat transparent center / 100%;
  margin: 0;
  padding: 0;
  opacity: 1;
  width: 25px;
  height: 25px;
  border-radius: 0;
}

.demo-modal .modal-body {
  padding-top: 0;
  font-size: 16px;
  color: var(--ColorTypeB);
  font-weight: var(--Regular);
  overflow: initial;
}

.demo-modal .modal-body .title {
  margin-bottom: 0.75rem;
  font-weight: var(--Semibold);
  font-size: 1.1em;
}

.demo-modal .modal-body .txtbox {
  position: relative;
  border-top: 3px solid var(--ColorTypeE);
  padding-top: 0.75rem;
  margin-bottom: 1.5rem;
}

.demo-modal .modal-body .txtbox::before {
  content: "";
  position: absolute;
  left: 0;
  top: -3px;
  width: 25%;
  height: 3px;
  background-color: var(--ColorTypeB);
}

.demo-modal .modal-body .txtbox .sub-title {
  margin-bottom: 0.25rem;
  font-weight: var(--Medium);
}

.demo-modal .modal-body .txtbox .contents {
  margin-bottom: 0.25rem;
  word-break: break-word;
  font-size: 0.9em;
}

.demo-modal .modal-body .txtbox .contents:last-child {
  margin-bottom: 0;
}

.demo-modal .modal-body .txtbox .contents .etc {
    font-size: 0.5em;
}

/* 모바일 반응형 */
@media screen and (max-width: 1024px) {

  .demo::before {
    display: none;
  }

  .demo .navbar {
    border-left: 0;
    border-right: 0;
  }

  .demo .sub-navbar {
    border-left: 0;
    border-right: 0;
  }

}

@media screen and (max-width: 599px) {

  .demo .Navbar-Toggler {
    width: 28px;
  }

  .demo .btn-back {
    width: 12px;
  }

  .demo .offcanvas .btn-close img.close {
    width: 16px;
  }

  .demo .offcanvas {
    max-width: 85%;
  }

  .demo .offcanvas-body {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .demo .offcanvas-header .offcanvas-title {
    font-size: 24px;
  }

  .demo .offcanvas-header .offcanvas-title .small {
    font-size: 15px;
  }

  .demo .offcanvas-header img.logo {
    width: 50px;
    margin-right: 10px;
  }

  .demo .offcanvas-header .offcanvas-title {
    width: calc(100% - 60px);
    font-size: 20px;
  }

  .demo .offcanvas-body .nav-item.last {
    margin-top: 0.5rem;
  }

  .demo .offcanvas-body .nav-link {
    padding-top: 0;
  }

  .demo .offcanvas-body .nav-link span {
    font-size: 18px;
  }

  .demo .offcanvas-body .btn-default {
    font-size: 16px;
    height: 45px;
  }

  .demo .offcanvas-body .btn-light {
    font-size: 16px;
    height: 45px;
  }

  .demo-modal .modal-header .modal-title {
    font-size: 20px;
  }

  .demo-modal .modal-header .btn-close {
    width: 18px;
    height: 18px;
  }

  .demo-modal .modal-body {
    font-size: 14px;
  }
}


@media screen and (max-width: 320px) {

  .demo .offcanvas-header {
    padding: 1rem 1.5rem !important;
  }

  .demo .offcanvas-header img.logo {
    width: 45px;
    margin-right: 10px;
  }

  .demo .offcanvas-body .nav-link {
    padding-bottom: 1rem !important;
  }

  .demo .offcanvas-body .nav-link span {
    font-size: 16px;
  }

  .demo .offcanvas-header .offcanvas-title {
    width: calc(100% - 55px);
    font-size: 17px;
  }

  .demo .offcanvas-body .btn-default {
    font-size: 14px;
    height: 40px;
  }

  .demo .offcanvas-body .btn-light {
    font-size: 14px;
    height: 40px;
  }


}


/* 23.01.09 추가 */

/*GmarketSan*/
@font-face {
  font-family: 'GmarketSansLight';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansLight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  transform: rotate(0.04deg);
}

@font-face {
  font-family: 'GmarketSansMedium';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  transform: rotate(0.04deg);
}

@font-face {
  font-family: 'GmarketSansBold';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  transform: rotate(0.04deg);
}

.gMarket.light {
  font-family: 'GmarketSansLight', 'Pretendard', sans-serif;
}

.gMarket {
  font-family: 'GmarketSansMedium', 'Pretendard', sans-serif;
}

.gMarket.bold {
  font-family: 'GmarketSansBold', 'Pretendard', sans-serif;
}



.datepicker-controls .view-switch {
  order: 0;
  justify-content: flex-start;
}

.datepicker-header .datepicker-controls .button:hover {
  background-color: transparent;
}

.datepicker-controls .button.prev-btn {
  order: 1;
}

.datepicker-controls .button.next-btn {
  order: 2;
}




/* 23-02-03 Footer 추가 */

.demo .Footer {
  position: relative;
  background-color: #000000;
  padding: 3rem 0;
}

.demo .Footer .footer-infobox {
  width: 90%;
  margin: auto;
}

.demo .Footer .footer-infobox .footer-img {
  width: 20%;
}

.demo .Footer .infobox1 {
  padding-top: 2rem;
}

.demo .Footer .infobox1 .infobox1-item {
  display: flex;
}

.demo .Footer .infobox1 .tit {
  flex: 0 0 auto;
  width: 28%;
  font-size: 17px;
  font-weight: var(--Regular);
  color: #fff;
  margin-bottom: 0;
}

.demo .Footer .infobox1 .desc {
  flex: 0 0 auto;
  width: 72%;
  font-size: 16px;
  color: #fff;
  margin-bottom: 0.25rem;
}

.demo .Footer .infobox2 {
  margin-top: 2rem;
}

.demo .Footer .infobox2 .call-tit {
  font-size: 24px;
  color: #fff;
  margin-bottom: 0.25rem;
}

.demo .Footer .infobox2 .call-num {
  font-size: 34px;
  color: #fff;
  font-weight: var(--Semibold);
  margin-bottom: 0;
  line-height: 1;
}

.demo .Footer .infobox2 .call-desc {
  font-size: 18px;
  color: #fff;
  margin-bottom: 15px;
}

.demo .Footer .infobox2 .call-mail {
  font-size: 25px;
  color: #fff;
  font-weight: var(--Medium);
}

.demo .Footer .infobox3 {
  margin-top: 1.5rem;
}

.demo .Footer .infobox3 .infobox3-nav {
  display: flex;
  margin-bottom: 0.5rem;
}

.demo .Footer .infobox3 .infobox3-nav>li {
  font-size: 16px;
  color: #fff;
  position: relative;
  margin-right: 1rem;
  padding-right: 1rem;
}

.demo .Footer .infobox3 .infobox3-nav>li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.demo .Footer .infobox3 .infobox3-nav>li::after {
  content: "";
  position: absolute;
  right: 0;
  top: 18%;
  width: 1px;
  height: 64%;
  background-color: #fff;
}

.demo .Footer .infobox3 .infobox3-nav>li:last-child::after {
  display: none;
}

.demo .Footer .infobox3 .infobox3-nav>li>span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
}

.demo .Footer .infobox3 .infobox3-txt {
  font-size: 16px;
  color: #fff;
  word-break: keep-all;
}


@media screen and (max-width: 599px) {

  .demo .Footer {
    padding: 2.5rem 0 1.5rem;
  }

  .demo .Footer .infobox1 {
    padding-top: 1rem;
  }

  .demo .Footer .infobox1 .tit {
    width: 35%;
    font-size: 14px;
  }

  .demo .Footer .infobox1 .desc {
    width: 65%;
    font-size: 14px;
  }

  .demo .Footer .infobox1 .desc br {
    display: none;
  }

  .demo .Footer .infobox2 .call-tit {
    font-size: 20px;
  }

  .demo .Footer .infobox2 .call-num {
    font-size: 28px;
  }

  .demo .Footer .infobox2 .call-desc {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .demo .Footer .infobox2 .call-mail {
    font-size: 20px;
  }

  .demo .Footer .infobox3 .infobox3-nav {
    flex-wrap: wrap;
  }

  .demo .Footer .infobox3 .infobox3-nav>li {
    font-size: 14px;
    margin-right: 0.5rem;
    padding-right: 0.5rem;
  }

  .demo .Footer .infobox3 .infobox3-txt {
    font-size: 13px;
  }

}


/* 23-02-06 offcanvas 수정 */

.demo.offcanvas {
  max-width: 65%;
  --bs-offcanvas-width: 445px;
}

.demo.offcanvas .btn-close {
  box-sizing: content-box;
  width: 28px;
  height: 28px;
  background: no-repeat;
}


.demo.offcanvas .btn-close img.close {
  width: 20px;
}


@media screen and (max-width: 599px) {

  .demo.offcanvas .btn-close img.close {
    width: 16px;
  }

  .demo.offcanvas {
    max-width: 85%;
  }

}





/* 23.02.03 */

.demo .offcanvas-header .offcanvas-title .imgbox {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  border-radius: 0.25rem;
  margin-left: 0.25rem;
}

.demo .offcanvas-header .offcanvas-title .imgbox.kakao {
  background: #FEE500
}

.demo .offcanvas-header .offcanvas-title .imgbox.naver {
  background: #5fc33a
}

.demo .offcanvas-header .offcanvas-title .imgbox.apple {
  background: var(--ColorTypeB);
}

.demo .offcanvas-header .offcanvas-title .imgbox .sns-icon {
  max-width: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.demo .offcanvas-header .offcanvas-title .imgbox.non-member .sns-icon {
  max-width: 100%;
}


@media screen and (max-width: 599px) {

  .demo .offcanvas-header .offcanvas-title .imgbox {
      width: 20px;
      height: 20px;
      margin-left: 0.15rem;
  }

  .demo .offcanvas-header .offcanvas-title .imgbox .sns-icon {
      max-width: 8px;
  }

}




/* 23-02-13 */


.demo.offcanvas::before {
  display: none;
}

.demo .PrdTbl-excelbox {
  margin-bottom: 1rem;
}

.demo .PrdTbl-excelbox .PrdTbl-down {
  display: flex;
  align-items: center;
  gap: 0 0.25rem;
  font-size: 18px;
  font-weight: var(--Semibold);
  color: var(--ColorTypeB);
}

.demo .PrdTbl-excelbox .PrdTbl-down span {
  text-decoration: underline;
  text-underline-position: under;
}

.demo .PrdTbl-excelbox .PrdTbl-down img {
  max-width: 26px;
}

.demo .PrdTbl-excelbox.tablet {
  display: none;
  font-size: 16px;
  color: var(--ColorTypeC);
  margin-bottom: 2.5rem;
}

.demo .PrdTbl-excelbox.tablet br{
  display: none;
}


@media screen and (max-width: 900px) {

  .demo .PrdTbl-excelbox.pc {
      display: none;
  }

  .demo .PrdTbl-excelbox.tablet {
      display: block;
  }
}



@media screen and (max-width: 599px) {

  .demo .PrdTbl-excelbox .PrdTbl-down {
      font-size: 16px;
      padding-right: 1.5rem;
  }

  .demo .PrdTbl-excelbox .PrdTbl-down img {
      max-width: 22px;
      padding-bottom: 0;
  }

  .demo .PrdTbl-excelbox.tablet {
      font-size: 14px;
      margin-bottom: 2rem;
  }

  .demo .PrdTbl-excelbox.tablet br{
      display: block;
  }

}


/* iOS 노치 */

.demo .container-fluid.pt-70px {
  padding-top: calc(constant(safe-area-inset-top) + 70px);
  padding-top: calc(env(safe-area-inset-top) + 70px);
}
.demo>div>.container-fluid>.navbar,
.demo>div>.container-fluid>.sub-navbar {
  padding-top: calc(constant(safe-area-inset-top) + 1.5rem);
  padding-top: calc(env(safe-area-inset-top) + 1.5rem);
}
.demo .offcanvas .offcanvas-btnbox {
  padding-top: calc(constant(safe-area-inset-top) + 1.5rem) !important;
  padding-top: calc(env(safe-area-inset-top) + 1.5rem) !important;
}
/* .demo .offcanvas-body {
  padding-bottom: calc(constant(safe-area-inset-bottom) + 2rem) !important;
  padding-bottom: calc(env(safe-area-inset-bottom) + 2rem) !important;
}
.demo .Footer {
  padding-bottom: calc(constant(safe-area-inset-bottom) + 3rem);
  padding-bottom: calc(env(safe-area-inset-bottom) + 3rem);
} */
.demo-modal .modal-header {
  padding-top: calc(constant(safe-area-inset-top) + 1.5rem);
  padding-top: calc(env(safe-area-inset-top) + 1.5rem);
}
.demo-modal .modal-body {
  padding-bottom: calc(constant(safe-area-inset-bottom) + 1.5rem);
  padding-bottom: calc(env(safe-area-inset-bottom) + 1.5rem);
}
@media screen and (max-width: 599px) {
  .demo>div>.container-fluid>.navbar,
  .demo>div>.container-fluid>.sub-navbar {
      padding-top: calc(constant(safe-area-inset-top) + 1rem);
      padding-top: calc(env(safe-area-inset-top) + 1rem);
  }
  /* .demo .Footer {
      padding-bottom: calc(constant(safe-area-inset-bottom) + 1.5rem);
      padding-bottom: calc(env(safe-area-inset-bottom) + 1.5rem);
  } */
}
.demo .offcanvas-btnbox {
  padding-top: calc(constant(safe-area-inset-top) + 1.5rem) !important;
  padding-top: calc(env(safe-area-inset-top) + 1.5rem) !important;
}
.demo .btn-back,
.demo .Navbar-Toggler,
.demo .sub-navbar>.btn-close {
    top: calc(constant(safe-area-inset-bottom) / 2 + 50%) !important;
    top: calc(env(safe-area-inset-bottom) / 2 + 50%) !important;
}

.demo .offcanvas-body .nav-link span::after {
  background-image: url(https://www.flexdaycdn.net/public/images/ticket_ver2/arrow-right.png);
}

.demo .offcanvas-body .nav-link.active span::after {
  background-image: url(https://www.flexdaycdn.net/public/images/ticket_ver2/arrow-right2.png);
}


/*Font NanumSquareNeo */

@font-face {
  font-family: 'NanumSquareNeo';
  src: url('./fonts/NanumSquareNeoTTF-aLt.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  transform: rotate(0.04deg);
}

@font-face {
  font-family: 'NanumSquareNeo';
  src: url('./fonts/NanumSquareNeoTTF-bRg.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  transform: rotate(0.04deg);
}

@font-face {
  font-family: 'NanumSquareNeo';
  src: url('./fonts/NanumSquareNeoTTF-cBd.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  transform: rotate(0.04deg);
}

@font-face {
  font-family: 'NanumSquareNeo';
  src: url('./fonts/NanumSquareNeoTTF-dEb.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  transform: rotate(0.04deg);
}

@font-face {
  font-family: 'NanumSquareNeo';
  src: url('./fonts/NanumSquareNeoTTF-eHv.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  transform: rotate(0.04deg);
}


/*Font SBAggro */

@font-face {
  font-family: 'SBAggro';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroL.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  transform: rotate(0.04deg);
}

@font-face {
  font-family: 'SBAggro';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroM.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  transform: rotate(0.04deg);
}

@font-face {
  font-family: 'SBAggro';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroB.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  transform: rotate(0.04deg);
}

.demo .navbar-brand {
  padding: 0;
  height: 35px;
  display: flex;
  gap: 0 0.75rem;
  align-items: center;
  flex: 1;
  text-align: left;
  margin: 0;
  padding-right: 0.75rem;
  width: calc(100% - 34px);
}

.demo .navbar-brand>.logo {
  max-width: 35px;
}

.demo .navbar-brand>.brand-txt {
  font-size: 25px;
  font-weight: var(--Semibold);
  position: relative;
  max-width: calc(100% - 35px - 4rem);
  overflow: hidden;
  text-overflow: ellipsis;
}

.demo .navbar-brand::after {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/nav_arrow.png) no-repeat center / 100%;
  margin-left: 0.25rem;
}

.demo .Navbar-Toggler {
  width: 34px;
}

.demo .Footer .footer-infobox .ver2 .accordion-button::after {
  background-image: url(https://www.flexdaycdn.net/public/images/ticket_ver2/footer_arrow.png);
}

.demo .Footer .footer-infobox .footer-img {
  width: 130px;
}

.demo .Footer .infobox1 .tit {
  font-size: 18px;
}

.demo .Footer .infobox1 .desc {
  font-size: 18px;
}

.demo .Footer .infobox2 .call-num {
  font-size: 30px;
  margin-bottom: 8px;
}

.demo .Footer .infobox2 .call-desc {
  margin-bottom: 0;
}

.demo .Footer .infobox2 .call-mail {
  font-size: 20px;
  font-weight: var(--Regular);
}

.demo .Footer .infobox3 .infobox3-nav>li {
  font-size: 18px;
  margin-right: 1.5rem;
  padding-right: 1.5rem;
}

.demoModal.modal.show .modal-dialog.modal-sm.modal-brand {
  transform: none;
}

.demoModal .modal-dialog.modal-sm.modal-brand {
  --bs-modal-width: 100%;
  width: 100%;
  transform: translateY(50px);
}

.demoModal .modal-brand {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  margin: 0;
}

.demoModal .modal-brand .modal-content {
  width: 100%;
  max-width: 800px;
  margin: auto;
  border: 0;
  border-radius: 0;
  background: #fff;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  overflow: hidden;
  will-change: transform;
}

.demoModal .modal-brand .modal-content .arrowDown {
  position: sticky;
  top: 0;
  text-align: center;
  background: linear-gradient(to bottom, #fff, transparent);
  padding: 0.75rem 0;
}

.demoModal .modal-brand .modal-content .arrowDown img {
  width: 30px;
}

.demoModal .modal-brand .modal-body {
  padding: 0 0 3rem;
  margin-top: 2rem;
  min-height: 15rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem 0;
}

.demoModal .modal-brand .selectInputbox>input {
  display: none;
}

.demoModal .modal-brand .selectInputbox>label {
  padding: 0 3rem;
  position: relative;
  font-size: 30px;
  font-weight: var(--Regular);
  color: var(--ColorTypeB);
  display: flex;
  gap: 0 0.5rem;
  justify-content: space-between;
  align-items: center;
  word-break: break-word;
}

.demoModal .modal-brand .selectInputbox>label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  width: 8px;
  height: 100%;
}

.demoModal .modal-brand .selectInputbox>label>.btnBasic {
  width: auto;
  height: auto;
  font-size: 22px;
  border-radius: 40px;
  padding: 0.25rem 1.5rem;
  white-space: nowrap;
}

.demoModal .modal-brand .selectInputbox>input:checked+label {
  font-weight: var(--Semibold);
}

.demoModal .modal-brand .selectInputbox>input:checked+label::before {
  background-color: var(--ColorTypeA);
}

.demoModal .modal-brand .selectInputbox>input:checked+label>.btnBasic {
  background-color: var(--ColorTypeD);
  border-color: var(--ColorTypeD);
}

.demo .offcanvas-weekmenu {
  padding: 1.5rem 2rem;
  background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/week_img.png) no-repeat calc(100% - 1.5rem) top / auto 75% #f7f7f7;
}

.demo .offcanvas-weekmenu>a {
  display: flex;
  align-items: center;
  gap: 0 0.75rem;
}

.demo .offcanvas-weekmenu .txt {
  max-width: 135px;
}

.demo .offcanvas-weekmenu .arrow {
  max-width: 20px;
  margin-bottom: 3px;
}

.demoModal .modal-content {
  border-radius: 5px;
}

.demoModal .modal-header {
  padding: 2rem 1.5rem 0;
}

.demoModal .modal-title {
  line-height: 1.3;
}

.demoModal .modal-body {
  padding: 1rem 0.5rem;
  font-weight: var(--Regular);
}

@media screen and (max-width:599px) {

  .demo .navbar-brand {
      gap: 0 0.5rem;
  }

  .demo .navbar-brand>.brand-txt {
      font-size: 17px;
      max-width: calc(100% - 35px - 2rem);
  }

  .demo .navbar-brand::after {
      width: 0.75rem;
      height: 0.75rem;
      margin-left: 0.1rem;
  }

  .demo .Navbar-Toggler {
      width: 26px;
  }

  .demo .Footer .footer-infobox .footer-img {
      width: 100px;
  }

  .demo .Footer .infobox1 .tit {
      font-size: 14px;
      width: 30%;
  }

  .demo .Footer .infobox1 .desc {
      font-size: 14px;
  }

  .demo .Footer .infobox2 .call-num {
      font-size: 22px;
      margin-bottom: 5px;
  }

  .demo .Footer .infobox2 .call-mail {
      font-size: 15px;
  }

  .demo .Footer .infobox3 .infobox3-nav>li {
      font-size: 14px;
      margin-right: 1.25rem;
      padding-right: 1.25rem;
  }

  .demoModal .modal-brand .modal-content {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
  }

  .demoModal .modal-brand .modal-content .arrowDown {
      padding: 0.25rem 0;
  }

  .demoModal .modal-brand .modal-content .arrowDown img {
      width: 18px;
  }

  .demoModal .modal-brand .modal-body {
      margin-top: 1.25rem;
      min-height: 10rem;
      gap: 1rem 0;
  }

  .demoModal .modal-brand .selectInputbox>label {
      padding: 0 1.5rem;
      font-size: 20px;
  }

  .demoModal .modal-brand .selectInputbox>label::before {
      width: 5px;
  }

  .demoModal .modal-brand .selectInputbox>label>.btnBasic {
      font-size: 15px;
      padding: 0.15rem 1rem;
  }

  .demo .offcanvas-weekmenu {
      padding: 1rem 2rem;
  }

  .demo .offcanvas-weekmenu>a {
      gap: 0 0.5rem;
  }

  .demo .offcanvas-weekmenu .txt {
      max-width: 100px;
  }

  .demo .offcanvas-weekmenu .arrow {
      max-width: 15px;
  }

  .demoModal .modal-body {
      padding-bottom: 0.5rem;
  }

}


@media screen and (max-width:320px) {

  .demo .Footer .infobox1 .tit {
      font-size: 12px;
  }

  .demo .Footer .infobox1 .desc {
      font-size: 12px;
  }

  .demo .Footer .infobox2 .call-tit {
      font-size: 18px;
  }

  .demo .Footer .infobox2 .call-num {
      font-size: 20px;
  }

  .demo .Footer .infobox2 .call-desc {
      font-size: 13px;
  }

  .demo .Footer .infobox3 .infobox3-nav>li {
      font-size: 12px;
  }

  .demoModal .modal-brand .modal-body {
      margin-top: 1rem;
      min-height: 8rem;
  }

  .demoModal .modal-brand .selectInputbox>label {
      font-size: 18px;
  }

  .demoModal .modal-brand .selectInputbox>label>.btnBasic {
      font-size: 13px;
  }

}

/* 23.05.10 수정 */
.demo .offcanvas-weekmenu>a {
  gap: 0 0.5rem;
}
.demo .offcanvas-weekmenu .arrow {
  max-width: 28px;
  margin-bottom: 0;
}
@media screen and (max-width: 599px) {
  .demo .offcanvas-weekmenu>a {
      gap: 0 0.25rem;
  }
  .demo .offcanvas-weekmenu .arrow {
      max-width: 20px;
  }
}